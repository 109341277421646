<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">Refund Policy</h1>
            </div>
            <div class="box b-shdw-3">
                <p v-html="content.main"/>
            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "RefundPolicy",
        mixins: [ contentAPI, page ]
    }
</script>

<style lang="scss" scoped>
    .level-item {
        padding: 40px 0 40px 0;
    }

    .content /deep/ {
        h3 {
            color: $c2g_blue;
            font-weight: bold;
        }
        h4 {
            color: $c2g_orange;
            margin-bottom: 8px !important;
        }
        p {
            margin: 0 0 15px 0;
        }
    }
</style>